import { LoaderCircle } from "lucide-react"
import { useEffect, useState } from "react"

import { cn } from "@/lib/utils"

export function FullScreenLoader() {
  const [hasRendered, setHasRendered] = useState(false)

  useEffect(() => {
    setTimeout(() => setHasRendered(true))
  }, [])

  return (
    <div
      className={cn(
        "fixed inset-0 z-50 flex items-center justify-center bg-white opacity-0 transition-all duration-200",
        hasRendered && "opacity-70"
      )}
    >
      <LoaderCircle
        style={{ animationDuration: "1250ms", animationDelay: "0ms" }}
        className={cn(
          "h-[25vw] w-[25vw] animate-spin opacity-0 transition-all delay-100 duration-1000",
          hasRendered && "opacity-30"
        )}
      />
    </div>
  )
}
