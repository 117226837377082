import { format } from "date-fns"
import { t } from "i18next"

import {
  Eat_Order_Table,
  Eat_Order_Table_Status,
} from "@/lib/__generated__/graphql"
import { cn } from "@/lib/utils"

interface OrdersListProps {
  orders: Eat_Order_Table[]
  onOrderSelect: (order: Eat_Order_Table) => void
}

export function OrdersList(props: OrdersListProps) {
  return (
    <div className="flex flex-col gap-3">
      {props.orders.map((order) => {
        const orderStatus = orderStatusMap[order.tableOrderStatus]
        if (!orderStatus) {
          return null
        }

        return (
          <button
            key={order.id}
            className={cn(
              "flex w-full items-center justify-between rounded-xl p-3 text-left text-xs",
              orderStatus === "SUCCESS" && "bg-emerald-100 text-emerald-600",
              orderStatus === "PENDING" && "bg-blue-100 text-blue-600",
              orderStatus === "FAILED" && "bg-red-100 text-red-600"
            )}
            onClick={() => props.onOrderSelect(order)}
          >
            <div className="flex flex-col">
              <p className="font-semibold">
                {t(
                  "Home - Button order infos",
                  "Order from {{time}} Table {{tableNumber}}",
                  {
                    time: format(order.createdAt, "HH:mm"),
                    tableNumber: order.tableNumber,
                  }
                )}
              </p>
              <p>
                {order.tableOrderStatus === "ORDER_DONE"
                  ? t(
                      "Home - Order delivered",
                      "Your order has been delivered."
                    )
                  : t(
                      "Home - Order in preparation",
                      "Your order is being prepared."
                    )}
              </p>
            </div>

            <div className="rounded-full bg-white p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 24 24"
              >
                <path
                  d="M13 9h-2V7a1 1 0 0 0-2 0v2H7a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2zm9.707 12.293l-5.682-5.682A8.948 8.948 0 0 0 19 10c0-4.962-4.038-9-9-9s-9 4.038-9 9 4.038 9 9 9a8.948 8.948 0 0 0 5.611-1.975l5.682 5.682a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414zM10 17c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </button>
        )
      })}
    </div>
  )
}

const orderStatusMap: Partial<
  Record<Eat_Order_Table_Status, "PENDING" | "SUCCESS" | "FAILED">
> = {
  ORDER_IN_PREPARATION: "PENDING",
  ORDER_DONE: "SUCCESS",
  ORDER_FAILED: "FAILED",
  PAYMENT_FAILED: "FAILED",
}
