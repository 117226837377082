import { useLayoutEffect } from "react"
import { HeadProvider, Meta, Title } from "react-head"
import { useTranslation } from "react-i18next"
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useParams,
} from "react-router-dom"
import { Toaster } from "sonner"

import { useParamsRequired } from "@/lib/navigation"

import CartPage from "./cart/cart-page"
import CheckoutPendingPage from "./checkout/checkout-pending-page"
import CheckoutPage from "./checkout/checkout-start-page"
import CheckoutSuccessPage from "./checkout/checkout-success-page"
import "./globals.css"
import { LoginPage } from "./login/login-page"
import MenuPage from "./menu/menu-page"
import OrderDetailsPage from "./order-details/order-details-page"
import OrdersPage from "./orders/orders-page"
import RestaurantPage from "./restaurant/restaurant-page"
import { ApolloWrapper } from "./shared-components/apollo-wrapper"
import { DataLoad } from "./shared-components/data-load"
import { Header } from "./shared-components/header"
import Home from "./start/start-page"

export default function App() {
  const { t } = useTranslation()

  //Since lang segment is optional, all child routes has to be added twice.
  //This is thanks to genius api changes in react-router-v6
  const localizedRoutes = (
    <>
      <Route path="" Component={Home} />
      <Route path=":restaurantId" Component={RestaurantWrapper}>
        <Route path="" Component={RestaurantPage} />
        <Route path="cart/item?/:itemId?" Component={CartPage} />
        <Route path="menu/item?/:itemId?" Component={MenuPage} />
        <Route path="details" Component={OrderDetailsPage} />
        <Route path="checkout" Component={CheckoutPage} />
        <Route path="checkout/pending" Component={CheckoutPendingPage} />
        <Route path="checkout/success" Component={CheckoutSuccessPage} />
        <Route path="orders" Component={OrdersPage} />
      </Route>
    </>
  )

  return (
    <ApolloWrapper>
      <HeadProvider>
        <Title>{t("Site - Page Title", { defaultValue: "LAAX Eat" })}</Title>
        <Meta
          name="description"
          content={t("Site - Page Description", {
            defaultValue: "Mobile ordering for LAAX restaurants",
          })}
        />
        <BrowserRouter>
          <Routes>
            <Route path="login" Component={LoginPage} />
            <Route path=":lang?/*" Component={LanguageChecker}>
              {localizedRoutes}
            </Route>
            {localizedRoutes}
          </Routes>
        </BrowserRouter>
        <Toaster position="top-center" duration={2000} />
      </HeadProvider>
    </ApolloWrapper>
  )
}

function LanguageChecker() {
  const { lang } = useParams()
  const { i18n } = useTranslation()
  useLayoutEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])
  return <Outlet />
}

function RestaurantWrapper() {
  const { restaurantId } = useParamsRequired("restaurantId")
  return (
    <>
      <DataLoad restaurantId={restaurantId} />
      <Header restaurantId={restaurantId} />
      <Outlet />
    </>
  )
}
