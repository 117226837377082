import { create } from "zustand"
import { persist } from "zustand/middleware"

import {
  Eat_Menu,
  Eat_Restaurant_GetRestaurantQuery,
  FullCartFragmentFragment,
} from "@/lib/__generated__/graphql"

interface MainStoreType {
  nameOnReceipt: string
  setNameOnReceipt: (name: string) => void

  tableNumber: number
  setTableNumber: (tableNumber: number) => void

  comments: string
  setComments: (comments: string) => void

  restaurant:
    | Eat_Restaurant_GetRestaurantQuery["Eat_Restaurant_GetRestaurant"]["restaurant"]
    | null
  setRestaurant: (
    restaurant: Eat_Restaurant_GetRestaurantQuery["Eat_Restaurant_GetRestaurant"]["restaurant"]
  ) => void

  menu: Eat_Menu | null
  setMenu: (menu: Eat_Menu) => void

  cart: FullCartFragmentFragment | null
  setCart: (cart: FullCartFragmentFragment) => void
}

export const useMainStore = create<MainStoreType>()(
  persist(
    (set) => ({
      nameOnReceipt: "",
      setNameOnReceipt: (nameOnReceipt) => set({ nameOnReceipt }),

      tableNumber: 0,
      setTableNumber: (tableNumber) => set({ tableNumber }),

      comments: "",
      setComments: (comments) => set({ comments }),

      restaurant: null,
      setRestaurant: (restaurant) => set({ restaurant }),

      menu: null,
      setMenu: (menu) => set({ menu }),

      cart: null,
      setCart: (cart) => set({ cart }),
    }),
    {
      name: "main-store",
      partialize: (state) => ({
        nameOnReceipt: state.nameOnReceipt,
        tableNumber: state.tableNumber,
        comments: state.comments,
      }),
    }
  )
)
