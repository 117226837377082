import { format } from "date-fns/format"
import { RefObject, useEffect, useRef, useState } from "react"

import { useTranslation } from "@/i18n/client"
import { cn, rappenToFrancs } from "@/lib/utils"

export function Receipt(props: {
  receiptRef?: RefObject<HTMLDivElement>
  expandReceipt: boolean
  restaurantName: string
  orderNumber?: number | null
  tableNumber?: string | null
  nameOnReceipt?: string
  createdAt?: Date
  items: { name: string; priceRappen: number; quantity: number }[]
  totalPriceRappen: number
  points: number
}) {
  return (
    <div ref={props.receiptRef} className="py-2">
      <div className="flex w-full flex-col gap-6 bg-gray-50 px-3 py-5">
        <ReceiptDetails
          expandReceipt={props.expandReceipt}
          restaurantName={props.restaurantName}
          orderNumber={props.orderNumber}
          tableNumber={props.tableNumber}
          nameOnReceipt={props.nameOnReceipt}
          createdAt={props.createdAt}
          totalPriceRappen={props.totalPriceRappen}
          items={props.items}
        />
        <ReceiptFooter points={props.points} />
      </div>
    </div>
  )
}

function ReceiptDetails(props: {
  expandReceipt: boolean
  restaurantName: string
  orderNumber?: number | null
  tableNumber?: string | null
  nameOnReceipt?: string
  createdAt?: Date
  totalPriceRappen: number
  items: { name: string; priceRappen: number; quantity: number }[]
}) {
  const { t } = useTranslation()

  const [height, setHeight] = useState(0)

  const receiptRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    props.expandReceipt && setHeight(receiptRef.current?.scrollHeight || 0)
  }, [props.expandReceipt, props.items])

  return (
    <div
      ref={receiptRef}
      className={cn(
        "flex h-0 flex-col gap-3 overflow-hidden transition-all duration-300"
      )}
      style={{ height: props.expandReceipt ? `${height}px` : "0" }}
    >
      <p className="text-center text-xl font-semibold">
        {props.restaurantName}
      </p>

      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          <div className="flex items-center justify-between px-3 text-xs">
            <p>
              {t("Receipt - Receipt", "Receipt")}: {props.orderNumber || "--"}
            </p>

            <div className="flex items-center gap-3">
              <p>
                {props.createdAt ? format(props.createdAt, "dd.MM.yy") : ""}
              </p>
              <p>{props.createdAt ? format(props.createdAt, "HH:mm") : ""}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-0.5">
          <div className="w-full border-[0.5px] border-dashed border-neutral-300" />
          <div className="w-full border-[0.5px] border-dashed border-neutral-300" />
        </div>

        <div className="flex items-center justify-between px-3 text-xs tracking-[0.13rem]">
          <p>{props.restaurantName}</p>
          <p>
            {t("Receipt - Table", "Table")} {props.tableNumber}
          </p>
        </div>

        <p className="text-center text-sm">
          {t("Receipt - For nameOnReceipt", "*For {{nameOnReceipt}}*", {
            nameOnReceipt: props.nameOnReceipt || "--",
          })}
        </p>

        {props.items.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between px-3 text-xs"
          >
            <p>
              {item.name} {item.quantity}x
            </p>
            <p>CHF {rappenToFrancs(item.priceRappen)}</p>
          </div>
        ))}

        <div className="w-full border-[0.5px] border-dashed border-neutral-300" />
        <div className="flex items-center justify-between px-3 text-lg">
          <p>{t("Receipt - Total", "Total")}</p>
          <p>CHF {rappenToFrancs(props.totalPriceRappen)}</p>
        </div>
      </div>
    </div>
  )
}

function ReceiptFooter(props: { points: number }) {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-col gap-1">
        {/* Todo: Handle loyality points */}
        {/* <div className="flex items-center justify-between text-xs text-red-500">
          <p>{t("Receipt - LAAX points", "LAAX points")}</p>
          <div className="flex items-center gap-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={12}
              height={12}
              viewBox="0 0 24 22"
            >
              <g>
                <path
                  d="M22.08 2A6.46 6.46 0 0 0 14.66.64a1.26 1.26 0 0 0-.65.73 1.33 1.33 0 0 0 0 1 1.28 1.28 0 0 0 .73.66 1.19 1.19 0 0 0 1 0 4 4 0 0 1 4.55.81 4.14 4.14 0 0 1 0 5.81L12 18.8 3.71 9.57a4.15 4.15 0 0 1 0-5.78 4 4 0 0 1 5.66 0l4.76 4.86a1.26 1.26 0 0 0 1.8 0 1.29 1.29 0 0 0 .37-.91 1.31 1.31 0 0 0-.37-.92L11.17 2a6.46 6.46 0 0 0-9.25 0A6.71 6.71 0 0 0 0 6.68a6.59 6.59 0 0 0 1.88 4.67L11 21.56l.1.1a.69.69 0 0 0 .17.11l.08.05a.57.57 0 0 0 .13.08 1.18 1.18 0 0 0 .52.1 1.23 1.23 0 0 0 .93-.42l9.16-10.18a6.79 6.79 0 0 0 0-9.45z"
                  fill="#ef4444"
                />
              </g>
            </svg>

            <p>--</p>
          </div>
        </div> */}
      </div>

      <div className="flex flex-col items-center gap-2 text-xs">
        <p className="text-center font-semibold">
          {t("Receipt - Thanks title", "Thank you very much for your order!")}
        </p>
        <p className="max-w-xs text-center text-muted-foreground">
          {t(
            "Receipt - Thanks subtitle",
            "We will do our best to bring your order to your table as quickly as possible."
          )}
        </p>
      </div>
    </>
  )
}
