import { X } from "lucide-react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { OrdersList } from "@/app/shared-components/orders-list"
import { Receipt } from "@/components/common/receipt/receipt"
import { Button } from "@/components/ui/button"
import { useTranslation } from "@/i18n/client"
import { Eat_Order_Table } from "@/lib/__generated__/graphql"
import { useMainStore } from "@/stores/main-store"

import { ViewMenuButton } from "./view-menu-button"

export function YourOrders(props: {
  restaurantId: string
  orders: Eat_Order_Table[]
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { restaurant, tableNumber } = useMainStore((state) => ({
    restaurant: state.restaurant,
    tableNumber: state.tableNumber,
  }))

  const [selectedOrder, setSelectedOrder] = useState<Eat_Order_Table>()

  if (!restaurant) {
    return null
  }

  if (selectedOrder) {
    return (
      <div className="relative flex flex-col items-center">
        <button
          className="absolute right-0 top-0 px-1.5 py-3"
          onClick={() => {
            setSelectedOrder(undefined)
          }}
        >
          <X strokeWidth={1.75} />
        </button>

        <Receipt
          expandReceipt={true}
          restaurantName={selectedOrder.restaurantName}
          tableNumber={selectedOrder.tableNumber}
          orderNumber={selectedOrder.orderNumber}
          nameOnReceipt={selectedOrder.nameOnReceipt}
          createdAt={selectedOrder.createdAt}
          items={selectedOrder.items}
          totalPriceRappen={selectedOrder.itemsTotalRappen}
          points={0}
        />
      </div>
    )
  }

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <div className="flex flex-col items-center">
        <p className="mb-5 text-3xl font-semibold">{restaurant.name}</p>

        <div className="flex flex-col items-center gap-3">
          <div className="flex items-center gap-1">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_582_46087)">
                <path d="M4.5 7V17ZM1.5 7H23.5ZM4.5 10H20.5ZM20.5 7V17Z" />
                <path
                  d="M4.5 7V17M1.5 7H23.5M4.5 10H20.5M20.5 7V17"
                  stroke="#000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_582_46087">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>
              {t("Home - Table", "Table")} {tableNumber}
            </p>
          </div>

          <div className="flex flex-col items-center gap-3 pt-3">
            <ViewMenuButton restaurantId={props.restaurantId} />
          </div>

          <div className="mt-5 w-full items-center">
            <div className="flex items-center justify-between gap-2">
              <h1 className="text-2xl font-semibold">
                {t("Home - Orders", "Orders")}
              </h1>
              <Button
                variant="link"
                onClick={() => {
                  navigate(`/${props.restaurantId}/orders`)
                }}
              >
                {t("Home - All orders button", "All orders")}
              </Button>
            </div>

            <OrdersList
              orders={props.orders}
              onOrderSelect={setSelectedOrder}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
