import { t } from "i18next"
import { useEffect, useState } from "react"

import { listOrdersToday } from "@/actions/list-orders-today"
import { Receipt } from "@/components/common/receipt/receipt"
import { Button } from "@/components/ui/button"
import { Eat_Order_Table } from "@/lib/__generated__/graphql"
import { getAnonId } from "@/lib/utils"

import { OrdersList } from "../shared-components/orders-list"

type GroupedOrders = Record<string, [Eat_Order_Table, ...Eat_Order_Table[]]>

export default function OrdersPage() {
  const [groupedOrders, setGroupedOrders] = useState<GroupedOrders>({})
  const [selectedOrder, setSelectedOrder] = useState<Eat_Order_Table>()

  useEffect(() => {
    const load = async () => {
      const anonId = getAnonId()

      const todayOrders = await listOrdersToday(anonId)
      const grouped = groupOrdersByRestaurant(todayOrders)
      setGroupedOrders(grouped)
    }

    load()
  }, [])

  if (Object.keys(groupedOrders).length === 0) {
    return <div>{t("Orders page - No orders found", "No orders found")}</div>
  }

  if (selectedOrder) {
    return (
      <div className="relative mt-14 flex flex-col items-center">
        <Receipt
          expandReceipt={true}
          restaurantName={selectedOrder.restaurantName}
          tableNumber={selectedOrder.tableNumber}
          orderNumber={selectedOrder.orderNumber}
          nameOnReceipt={selectedOrder.nameOnReceipt}
          createdAt={selectedOrder.createdAt}
          items={selectedOrder.items}
          totalPriceRappen={selectedOrder.itemsTotalRappen}
          points={0}
        />

        <div>
          <Button
            onClick={() => {
              setSelectedOrder(undefined)
            }}
          >
            {t("Orders page - Close button", "Close")}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="mt-14 px-5">
      {Object.entries(groupedOrders).map(([restaurantId, orders]) => (
        <div key={restaurantId} className="mb-4">
          <h2 className="py-2 text-xl font-bold">{orders[0].restaurantName}</h2>
          <OrdersList orders={orders} onOrderSelect={setSelectedOrder} />
        </div>
      ))}
    </div>
  )
}

function groupOrdersByRestaurant(orders: Eat_Order_Table[]): GroupedOrders {
  return orders.reduce((groupedOrders, order) => {
    const restaurantId = order.restaurantId
    if (!groupedOrders[restaurantId]) {
      groupedOrders[restaurantId] = [order]
    } else {
      groupedOrders[restaurantId].push(order)
    }
    return groupedOrders
  }, {} as GroupedOrders)
}
