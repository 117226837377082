import { graphql } from "@/lib/__generated__"
import {
  Eat_Order_Table,
  MutationEat_Order_CreateTableOrderArgs,
} from "@/lib/__generated__/graphql"
import apolloClient from "@/lib/apollo/apollo-client"

export async function createTableOrder(
  variables: MutationEat_Order_CreateTableOrderArgs
): Promise<Eat_Order_Table | undefined> {
  const { data } = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Order_CreateTableOrder(
        $input: Eat_Order_CreateTableOrder_Input!
        $anonId: ID
      ) {
        Eat_Order_CreateTableOrder(input: $input, anonId: $anonId) {
          order {
            accountIdentifier
            anonId
            commentOnReceipt
            createdAt
            email
            id
            items {
              modifiers {
                groupName
                modifierId
                modifierName
              }
              name
              pricePoints
              priceRappen
              quantity
              sku
              subItems {
                name
                quantity
                sku
                modifiers {
                  groupName
                  modifierId
                  modifierName
                }
              }
            }
            itemsTotalPoints
            itemsTotalRappen
            nameOnReceipt
            commentOnReceipt
            orderNumber
            orderTotalPoints
            orderTotalRappen
            payment {
              id
            }
            restaurantId
            restaurantName
            tableNumber
            tableOrderStatus
            tipRappen
            userId
          }
        }
      }
    `),
    variables,
  })

  return data?.Eat_Order_CreateTableOrder.order
}
