import { InMemoryCache, createFragmentRegistry } from "@apollo/client/cache"
import { ApolloClient } from "@apollo/client/core/ApolloClient"
import { setContext } from "@apollo/client/link/context"
import { from } from "@apollo/client/link/core/from"
import { createHttpLink } from "@apollo/client/link/http/createHttpLink"
import i18next from "i18next"

import { FullCartFragment } from "@/actions/cart/full-cart-fragment"

import { auth, webViewAuth } from "../auth"

// ===========LocaleLink===========
const localeLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    "Accept-Language": i18next.language,
  },
}))

// ===========HTTPLink===========
const httpLink = createHttpLink({
  uri: import.meta.env.VITE_PUBLIC_API_URL,
})

// ===========AuthLink===========
const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

async function getAuthToken() {
  const isAuthenticated = await auth.isAuthenticated()
  const token = isAuthenticated ? await auth.getTokenSilently() : null
  const webViewToken = webViewAuth.getToken()
  return token || webViewToken
}

const links = from([localeLink, authLink, httpLink])

const createApolloClient = () => {
  return new ApolloClient({
    link: links,
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(FullCartFragment),
    }),
  })
}

const apolloClient = createApolloClient()

export default apolloClient
